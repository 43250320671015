<template>
  <div class="containertop">
    <div class="bg">
      <nav-bar :isFixed="true"></nav-bar>
      <img class="logo" src="@/assets/image/login/e-Rith.png" alt="" />
    </div>
    <div class="login-form">
      <van-tabs
        v-model:active="active"
        title-active-color="#1A1A1A"
        title-inactive-color="#BDBDBD"
        @click-tab="handleLoginTab"
      >
        <van-tab :title="$t('電子郵箱')" name="2"></van-tab>
        <van-tab :title="$t('電話號碼')" name="1"></van-tab>
      </van-tabs>
      <div v-if="active === '1'">
        <div class="phone" @click="show = true">
          <span>{{ phoneLabel }}</span>
          <img
            class="arrow"
            src="@/assets/icons/arrow-rightactive.png"
            alt=""
          />
        </div>
        <van-form @submit="onSubmit">
          <div>
            <van-field v-model="form.userName" :placeholder="$t('手機號')" />
            <div class="error" v-if="verifyobj.userName">{{$t('請輸入')}}{{ $t('正確手機號碼') }}</div>
          </div>
          <div>
            <van-field
              v-model="form.password"
              :type="isPassword ? 'password' : 'text'"
              @click-right-icon="isPassword = !isPassword"
              :placeholder="$t('密碼')"
            >
              <template #right-icon>
                <div>
                  <img
                    v-if="isPassword"
                    style="width: 20px"
                    src="@/assets/icons/eyes.png"
                    alt=""
                  />
                  <img
                    v-else
                    style="width: 20px"
                    src="@/assets/icons/eye-open.png"
                    alt=""
                  />
                </div>
              </template>
            </van-field>
            <div class="error" v-if="verifyobj.password">{{$t('請輸入')}}{{$t('密碼')}}</div>
          </div>
          <div style="color: #90d12e;"  class="Forgot" @click="gotoForgot">{{$t('忘記密碼')}}?</div>
          <div style="margin: 16px;">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              color="rgba(144, 209, 46, 1)"
              >{{$t('登入')}}</van-button
            >
          </div>
        </van-form>
      </div>
      <div v-else>
        <van-form @submit="onSubmit">
          <div>
            <van-field v-model="form.userName" :placeholder="$t('郵箱')" />
            <div class="error" v-if="verifyobj.userName"> {{ $t('請輸入') }} {{$t('郵箱')}}</div>
          </div>
          <div>
            <van-field
              v-model="form.password"
              :type="isPassword ? 'password' : 'text'"
              @click-right-icon="isPassword = !isPassword"
              :placeholder="$t('密碼')"
            >
              <template #right-icon>
                <div>
                  <img
                    v-if="isPassword"
                    style="width: 20px"
                    src="@/assets/icons/eyes.png"
                    alt=""
                  />
                  <img
                    v-else
                    style="width: 20px"
                    src="@/assets/icons/eye-open.png"
                    alt=""
                  />
                </div>
              </template>
            </van-field>
            <div class="error" v-if="verifyobj.password">{{$t('請輸入')}}{{$t('密碼')}}</div>
          </div>
          <div style="color: #90d12e;"  class="Forgot" @click="gotoForgot">{{$t('忘記密碼')}}?</div>
          <div style="margin: 16px;">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              color="rgba(144, 209, 46, 1)"
              >{{$t('登入')}}</van-button
            >
          </div>
        </van-form>
      </div>
      <div class="register" @click="openRegister"><span style="color: #1a1a1a;">{{$t('沒有帳戶？')}}</span>{{$t('創建帳戶')}}</div>
    </div>
    <van-popup
      v-model:show="show"
      position="bottom"
      :close-on-click-overlay="false"
      :style="{ height: '40%', borderRadius: '16px 16px 0 0' }"
    >
      <div class="titleArea">
        <div></div>
        <div>{{$t('國家地區')}}</div>
        <div class="close">
          <img
            @click="show = false"
            style="width: 100%"
            src="@/assets/icons/ic_Close@2x.png"
            alt=""
          />
        </div>
      </div>
      <div class="phone-list">
        <div
          :class="['phone-item', { actvie: item.code == selectPhone }]"
          v-for="item in phoneCode"
          :key="item.code"
          @click="handleAreaCode(item)"
        >
          <div class="phone-text">{{ item.name }}</div>
          <img
            class="phone-check"
            v-if="item.code == selectPhone"
            src="@/assets/icons/check.png"
            alt=""
          />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getLogin } from "@/api/login";
import { setToken,getToken } from "@/utils/token";
import {  mapState } from 'vuex';
export default {
  data() {
    return {
      form: {
        captcha: "1",
        userName: "",
        password: "",
        errorCount: 0,
        autoLogin: false,
        areaCode: "+852",
        platform:4 //区分设备类型
      },
      active: "2",
      isPassword: true,
      isEmail: false,
      isEyes: false,
      columns: [
        {
          value: "1",
          label: "HK(+852)"
        },
        {
          value: "2",
          label: "MO(+853)"
        }
      ],
      show: false,
      selectPhone: "",
      phoneLabel: "",
      isRegister: false,
      verifyobj: {
        userName: "",
        password: ""
      },
      verifyList: []
    };
  },
  computed:{
    ...mapState('user',['phoneCode'])
  },
  async created() {
    // if(getToken()){
    // this.$router.push('/')
    // }
    await this.$store.dispatch("user/getPhoneCode");
    this.selectPhone=this.phoneCode[0]?.code;
    this.phoneLabel=this.phoneCode[0]?.name;
  },
  methods: {
    gotoForgot() {
      console.log("forgot");

      this.$router.push("/rorgotPassword");
    },
    handleLoginTab() {
      this.verifyList = [];
      this.validatoForm();
    },
    gotoRegister() {
      this.isRegister = false;
      this.$router.push("/register");
    },
    // 注册
    openRegister() {
      // this.isRegister = true;
      this.$router.push("/register");
    },
    handleAreaCode(item) {
      this.selectPhone = item.code;
      this.show = false;
      this.form.areaCode = item.code;
      this.phoneLabel = item.name;
    },
    validatorPhone(val) {},
    validatorEmail(val) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(val);
    },
    validatoForm() {
      if (this.verifyList.length === 0) {
        for (const key in this.verifyobj) {
          this.verifyobj[key] = "";
        }
      } else {
        this.verifyList.forEach(item => {
          this.verifyobj[item] = true;
        });
      }
    },
    onChange(val) {
      this.form.areaCode = val;
      this.show = false;
    },
    async onSubmit() {
      console.log(this.form);

      if (this.active === "2") {
        this.form.areaCode = undefined;
      } else {
        this.form.areaCode = this.selectPhone;
      }

      if (!this.form.userName) {
        this.verifyList.push("userName");
      } else {
        const index = this.verifyList.findIndex(item => item === "userName");
        if (index != -1) {
          this.verifyList.splice(index, 1);
        }
      }
      if (!this.form.password) {
        this.verifyList.push("password");
      } else {
        const index = this.verifyList.findIndex(item => item === "password");
        console.log();
        if (index != -1) {
          this.verifyList.splice(index, 1);
        }
      }

      this.validatoForm();
      console.log(this.verifyobj);
      if (this.verifyList.length > 0) {
        return;
      }
      const res = await getLogin(this.form);
      if (res.resultID === 1200) {
        setToken(res.data.token);
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        }else{
          this.$router.push("/");
        }
        this.$store.dispatch("user/getUserInfo");
      } else {
        this.$toast(res.message);
      }
    }
  }
};
</script>

<style lang="scss">
.containertop {
  .bg {
    height: 210px;
    background: linear-gradient(
      180deg,
      rgba(144, 209, 46, 0.3) 0%,
      rgba(144, 209, 46, 0) 100%
    );
    border-radius: 0px 0px 0px 0px;
    padding-top: 88px;
  }
  .logo {
    width: 166px;
    margin: 0 0 40px 32px;
  }
  .van-field {
    background-color: transparent;
    padding: 12px;
    border-bottom: 2px solid #ebebeb;
    &::after {
      display: none;
    }
  }
  .van-tabs {
    width: 60%;
  }
  .van-tabs__nav {
    background-color: transparent;
    height: auto;
    .van-tabs__line {
      display: none;
    }
    .van-tab:first-child {
      border-right: 1px solid #bdbdbd;
    }
    .van-tab__text {
      font-size: 18px;
    }
  }
  .arrow {
    width: 16px;
    height: 16px;
  }
  .phone {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #1a1a1a;
    margin: 12px 0px 20px 14px;
  }
  .login-form {
    padding: 0 22px;
    .error {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #f82207;
      line-height: 18px;
      padding-left: 12px;
      margin-top: 1px;
    }
  }
}
.Forgot {
  display: flex;
  justify-content: end;
  font-size: 15px;
  color: #1a1a1a;
  line-height: 24px;
  margin-top: 20px;
}
.register {
  text-align: center;
  color: #90d12e;
}
.titleArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 0;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;

  .close {
    width: 20px;
  }
}
.phone-list {
  padding: 20px 16px;
  .actvie {
    border: 2px solid #90d12e !important;
  }
  .phone-item {
    padding: 20px;
    position: relative;
    background: #f8f8f8;
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 12px;
    border: 2px solid transparent;
    .phone-text {
      font-size: 16px;
      color: #1a1a1a;
      line-height: 16px;
      text-align: center;
    }
    .phone-check {
      position: absolute;
      width: 24px;
      bottom: -1px;
      right: -1px;
    }
  }
}
.register-dialog {
  padding: 24px;
}
.register-text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 15px;
  color: #666666;
  line-height: 24px;
  text-align: center;
  span {
    color: #3491fa;
  }
}
.register-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .Cancel,
  .Confirm {
    flex: 1;
    text-align: center;
    margin-top: 32px;
  }
  .Cancel {
    background: #eeeeee;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
    margin-right: 12px;
  }
  .Confirm {
    font-size: 16px;
    color: #ffffff;
    background-color: #90d12e;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
  }
}
</style>
